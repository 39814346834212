import React from "react";
import {Box, Image, Button} from "grommet";
import { useNavigate } from "react-router-dom";


export const ImageViewer = (props) => {
    const navigate = useNavigate();

return (
<Box id="ImageContainer" animation="fadeIn" align="center" overflow={{vertical: "hidden", horizontal:"auto"}} height={{min:"50%", max:"80%"}} >
    <Button  label="Back" size="small" style={{position:"absolute", right:"5%", top:"10%"}} onClick={() => navigate(-1)}/>
        <Image id='headerImage' alignSelf="start" fit='contain' src={props.imageUrl} />
</Box>

);

}
export default ImageViewer; 