import {React, useEffect} from 'react';
import './LoadingIndicator.css';

const LoadingIndicator = () => {
    useEffect(() => {
        let icon = document.querySelector("#loading-indicator");

        if (icon) {
            let paths = icon.querySelectorAll("path");
            if (paths.length) {
                for (let [index, path] of paths.entries()) {
                    let pathLength = path.getTotalLength();
                    console.log(`Path #${index + 1}: ${pathLength}`);
                }
            }
        }
    }, []); 
    
    return (
        <div id="loading-container" style={{width:"600px", display:"flex", flexFlow:"row", justifyContent:"center"}}>
            <svg id="loading-indicator" xmlns="http://www.w3.org/2000/svg" width="300" height="300" style={{shapeRendering:"geometricPrecision", textRendering:"geometricPrecision", imageRendering:"optimizeQuality", fillRule:"evenodd", clipRule:"evenodd"}}>
                <path class="char-bets" d="M48.5 21.5c19.333-.175 27.166 9.159 23.5 28-1.972 4.471-5.138 7.805-9.5 10 8.686 3.526 13.02 10.026 13 19.5-1.323 13.323-8.657 20.657-22 22a100.2 100.2 0 0 1-20 0L32 99.5a600.47 600.47 0 0 1 0-49 307.527 307.527 0 0 1 16.5-29Zm-4 12c14.517-1.429 18.85 4.405 13 17.5-4.096 2.51-8.43 3.01-13 1.5a47.064 47.064 0 0 1 0-19Zm0 32c13.977-.863 19.477 5.47 16.5 19-4.57 4.817-10.07 6.15-16.5 4a68.308 68.308 0 0 1 0-23ZM171.5 25.5c13.579-.323 27.079.01 40.5 1 .789 3.591.622 7.092-.5 10.5a102.63 102.63 0 0 0-13 1.5l-2 61a407.154 407.154 0 0 1-11.5-34l-1-26-1.5-1.5c-4.253-.2-8.42-.7-12.5-1.5-.987-3.975-.487-7.642 1.5-11ZM84.5 33.5c11.005-.166 22.005 0 33 .5 1.333 3.333 1.333 6.667 0 10l-21 1c-1.333 2.333-1.333 4.667 0 7l18 1c2.318 3.282 2.651 6.782 1 10.5-6.009-.166-12.009 0-18 .5-1.26 2.213-1.926 4.713-2 7.5.074 2.787.74 5.287 2 7.5l20 1c1.333 3.333 1.333 6.667 0 10-11 .667-22 .667-33 0L83 88.5a702.465 702.465 0 0 1 0-53c.717-.544 1.217-1.21 1.5-2ZM227.5 33.5c15.355-2.106 24.522 4.228 27.5 19-3.473 2.323-7.306 3.323-11.5 3-1.26-8.45-5.927-11.617-14-9.5-4.735 3.991-5.235 8.491-1.5 13.5A180.876 180.876 0 0 0 245.5 74c11.181 8.868 13.681 19.701 7.5 32.5-4.778 5.804-10.945 8.471-18.5 8-14.788-.617-22.122-8.117-22-22.5 3.272-1.723 6.606-2.223 10-1.5 1.344 7.175 5.511 11.509 12.5 13 6.986-1.633 9.652-5.967 8-13l-3-5a325.813 325.813 0 0 1-23-20c-7.536-14.544-4.036-25.21 10.5-32Z"/>
                <path class="piece-b"   fill="#e9e3de" d="M39.5 19.5c.79.283 1.456.783 2 1.5-3.162 8.15-6.329 16.316-9.5 24.5a226.522 226.522 0 0 1-4.5-21 7.253 7.253 0 0 1 2-2.5 135.373 135.373 0 0 0 10-2.5Z"/>
                <path class="char-a"    fill="#e9e3de" d="M138.5 5.5c5.344-.166 10.677 0 16 .5l1.5 1.5a2391.677 2391.677 0 0 1 10.5 52c11.487-2.028 14.82 2.139 10 12.5-2.31.497-4.643.663-7 .5a717.94 717.94 0 0 1 3 16.5c.067 3.947-1.933 5.447-6 4.5-2.511.212-4.678-.455-6.5-2a388.11 388.11 0 0 1-4-17l-1.5-1.5a64.247 64.247 0 0 0-16 0 1643.912 1643.912 0 0 0-9.5 39.5c-3.667 1.333-7.333 1.333-11 0a12.494 12.494 0 0 1 0-7 2948.328 2948.328 0 0 1 20.5-100Zm7 20c1.149.291 1.816 1.124 2 2.5a238.079 238.079 0 0 1 5 31.5c-3.937.294-7.77-.04-11.5-1a833.646 833.646 0 0 1 4.5-33Z"/>
                <path class="piece-t"   fill="#e9e3de" d="M182.5 75.5c1.636 1.249 2.803 2.916 3.5 5a136.048 136.048 0 0 1 7.5 21c-2.948.277-5.781-.056-8.5-1a498.89 498.89 0 0 1-2.5-25Z"/>
                <path class="char-in"   fill="#e9e3de" d="M37.5 110.5a40.911 40.911 0 0 1 9 .5l1.5 1.5c.667 17 .667 34 0 51-3.183 2.023-6.683 2.523-10.5 1.5l-1.5-1.5c-.667-17-.667-34 0-51 .717-.544 1.217-1.211 1.5-2ZM58.5 115.5c3.35-.165 6.683.002 10 .5l2.5 2.5 30 72c.684 1.284.517 2.451-.5 3.5a25.238 25.238 0 0 1-10 0 4.458 4.458 0 0 1-2-1.5 1131.161 1131.161 0 0 0-18.5-43 686.696 686.696 0 0 1-1.5 37c-3.532 1.303-7.032 1.303-10.5 0a849.548 849.548 0 0 1 .5-71Z"/>
                <path class="piece-n"   fill="#e9e3de" d="M99.5 98.5a46.127 46.127 0 0 0 8 1.5 23.256 23.256 0 0 1 1 6.5 1249.407 1249.407 0 0 1-7.5 76 172.673 172.673 0 0 1-9.5-26c1.767-18.666 3.6-37.333 5.5-56 .995-.566 1.828-1.233 2.5-2Z"/>
                <path class="char-t"    fill="#e9e3de" d="M125.5 132.5c13.579-.323 27.079.01 40.5 1 .789 3.591.622 7.091-.5 10.5l-11 1a9.454 9.454 0 0 0-2.5 1.5l-1 18a343.675 343.675 0 0 1-11.5 22 801.488 801.488 0 0 1-1.5-40l-1.5-1.5c-4.253-.2-8.42-.7-12.5-1.5-.987-3.975-.487-7.641 1.5-11Z"/>
                <path class="piece-t2"  fill="#e9e3de" d="M152.5 172.5c.715 5.572.215 11.239-1.5 17-3.366 1.495-6.532 1.162-9.5-1a7672.936 7672.936 0 0 1 11-16Z"/>
                <path class="char-h"    fill="#e9e3de" d="M175.5 107.5c3.507-.506 6.674.16 9.5 2l1 37 1.5 1.5c4.722.777 9.389.61 14-.5a373.519 373.519 0 0 1 2.5-25c3.892-1.567 7.558-1.233 11 1a1599.45 1599.45 0 0 1 0 80c-3.183 2.023-6.683 2.523-10.5 1.5a9.454 9.454 0 0 1-1.5-2.5 883.56 883.56 0 0 0-1.5-42c-4.611-1.11-9.278-1.277-14-.5a9.454 9.454 0 0 0-1.5 2.5l-1 17c-3.667 2.667-7.333 2.667-11 0a1224.638 1224.638 0 0 1 0-70c.717-.544 1.217-1.211 1.5-2Z"/>
                <path class="char-e"    fill="#e9e3de" d="M225.5 129.5a60.931 60.931 0 0 1 11 .5 70.26 70.26 0 0 1 13 9.5 429.937 429.937 0 0 1-12 2c-1.287 3.209-1.287 6.375 0 9.5l18 1c2.22 3.101 2.553 6.435 1 10a102.954 102.954 0 0 1-19 2 47.682 47.682 0 0 0-1 9.5c.054 3.439.72 6.606 2 9.5l20 1c1.333 3.333 1.333 6.667 0 10-11.243.82-22.41.653-33.5-.5a256.13 256.13 0 0 1-1.5-31.5l.5-29.5 1.5-3Z"/>
                <path class="piece-e"   fill="#e9e3de" d="M244.5 127.5a175.824 175.824 0 0 0 15 2.5l2 3a26.67 26.67 0 0 0-1.5 6.5c-1.938 2.478-4.105 2.645-6.5.5a77.742 77.742 0 0 0-10-11c.556-.383.889-.883 1-1.5Z"/>
                <path class="char-woos" fill="#e9e3de" d="M14.5 175.5c3.507-.506 6.674.16 9.5 2 4.774 24.98 9.441 49.98 14 75a3478.748 3478.748 0 0 0 9-47c3.442-2.233 7.108-2.567 11-1 3.46 15.634 6.793 31.3 10 47a564.055 564.055 0 0 0 7-38c4.261-2.981 8.095-2.648 11.5 1l-12 66c-3.867 2.633-7.7 2.633-11.5 0a3187.9 3187.9 0 0 1-10-49 515.636 515.636 0 0 0-7.5 42c-1.175.219-2.175-.114-3-1a1314.784 1314.784 0 0 0-20.5-44L12.5 179c.362-1.434 1.028-2.601 2-3.5ZM157.5 194.5c9.452-1.762 17.619.571 24.5 7a32.473 32.473 0 0 1 4 10 344.478 344.478 0 0 1-1 51c-7.238 12.635-17.738 16.135-31.5 10.5-5.281-3.382-8.448-8.216-9.5-14.5-.667-16-.667-32 0-48 1.859-7.683 6.359-13.016 13.5-16Zm5 11c5.17-.685 8.67 1.315 10.5 6a76.88 76.88 0 0 1 1.5 17h-19a67.63 67.63 0 0 1 1.5-18c1.107-2.613 2.94-4.28 5.5-5Zm-7 35h19a94.985 94.985 0 0 1-1.5 18c-1.399 3.738-4.066 5.404-8 5-3.668.5-6.334-.834-8-4a79.973 79.973 0 0 1-1.5-19ZM106.5 204.5c6.561-.581 12.895.252 19 2.5 4.709 3.237 7.542 7.737 8.5 13.5a146.886 146.886 0 0 1-1 33c-3.651 8.421-10.151 12.754-19.5 13-12.367-.537-19.533-6.871-21.5-19a125.701 125.701 0 0 1 1-30c2.442-6.612 6.942-10.945 13.5-13Zm3 11c7.324-1.263 11.491 1.737 12.5 9 .667 7 .667 14 0 21-.952 7.624-5.118 10.457-12.5 8.5-2.167-.833-3.667-2.333-4.5-4.5-2.183-10.426-1.85-20.759 1-31a30.233 30.233 0 0 1 3.5-3ZM256.5 204.5c14.099-1.637 23.099 4.196 27 17.5-.255 1.326-.922 2.326-2 3-5.967 3.232-9.8 1.732-11.5-4.5-5.099-6.496-10.099-6.496-15 0-.652 3.094-.318 6.094 1 9a325.89 325.89 0 0 0 23 20c6.081 8.362 7.081 17.362 3 27-8.552 9.248-18.719 11.415-30.5 6.5-6.462-3.923-10.462-9.59-12-17 2.102-3.474 5.269-5.307 9.5-5.5 2.521 4.486 5.688 8.653 9.5 12.5 12.078 1.673 15.911-3.161 11.5-14.5a228.87 228.87 0 0 0-19.5-16.5c-11.4-11.133-11.733-22.467-1-34l7-3.5Z"/>
                <path class="piece-w"   fill="#e9e3de" d="M21.5 242.5a341.011 341.011 0 0 1 22 37 4.94 4.94 0 0 1-1 2.5 34.041 34.041 0 0 0-7.5 1.5l-3-2A1223.148 1223.148 0 0 0 20.5 244c.556-.383.89-.883 1-1.5Z"/>
                <path class="char-d"    fill="#e9e3de" d="M213.5 215.5c12.02-.915 19.52 4.419 22.5 16a240.38 240.38 0 0 1 0 31c-1.236 6.902-5.069 11.735-11.5 14.5a112.26 112.26 0 0 1-29 1 189.814 189.814 0 0 1-.5-33.5c6.177-9.695 12.344-19.362 18.5-29Zm-5 11c9.198-1.793 14.365 1.874 15.5 11 1.08 8.826.413 17.493-2 26-4.667 4.144-9.667 4.811-15 2a342.33 342.33 0 0 1 0-37c.717-.544 1.217-1.211 1.5-2Z"/>
                <path class="piece-d"   fill="#e9e3de" d="M195.5 214.5h11a97.981 97.981 0 0 1-11 24 3.647 3.647 0 0 1-1.5-1 183.182 183.182 0 0 1-1.5-22c1.291.237 2.291-.096 3-1Z"/>
            </svg>
        </div>
    );
};
export default LoadingIndicator;