import React, {useEffect} from "react";
import logo from '../media/images/banner.png';
import {Box, Image, Text} from "grommet";


export const OpenPage = (props) => {
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    
    useEffect(() => {
           try {
            window.open(props.url, "_self");
           } catch (e){
            setError(true);
            setLoading(false);
           }
      }, [props.url])

return (
    <Box animation="fadeIn" align="center">
        <Box gap="1em" fill="horizontal" width={{max:"600px"}} >
            <Image
                id='headerImage'
                fit='contain'
                src={logo}
            />
            <Box pad="large"
                align="center"
                animation= "slideDown"
                >
                {loading ? (
                <Text color="pink" textAlign="center" style={{maxWidth: '580px', margin: "1em"}}>
                    Opgevraagde pagina openen
                </Text>
                ) : null
                }
                {error ? (
                <Text color="red" textAlign="center" style={{maxWidth: '580px', margin: "1em"}}>
                    Kon opgevraagde pagina niet openen.
                    Stuur een mail naar info@BeatsInTheWoods.com en we helpen je graag verder. 
                </Text>
                ) : null
                }
            </Box>
        </Box>
    </Box>
);

}
export default OpenPage;