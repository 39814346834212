import {UNAUTHENTICATED_API, AUTHENTICATED_API} from "../constants";

export async function ReviveMendixV2(){
    return await fetch(UNAUTHENTICATED_API + 'Common/Revive', {
        mode: 'cors'
    })
}

export async function Preregistrate(event, guest, reCaptchaToken){
    const payload = {
        "first_name": guest.firstName,
        "last_name": guest.lastName,
        "email": guest.email,
        "approach_for_marketing": guest.approach,
        "ReCaptchaToken": {
            "token": reCaptchaToken
        }
    }
    return await fetch(UNAUTHENTICATED_API + 'Events/'+event+'/Preregistrate', {
        method: "POST",
        mode: 'cors',
        body: JSON.stringify(payload)
    })
}

export async function guestIsGoing(userId, eventId, going){
    return await fetch(AUTHENTICATED_API + "Event/"+eventId+'/'+userId+'?Going='+going, {
        method: 'PUT', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            //'Authorization': "Basic " + btoa(`${username}:${password}`),
            'Authorization': btoa('WpqA0NyKigAELp9G41yHzC6devHYr5'),
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });
}


export async function unsubscribe(email){
    return fetch(UNAUTHENTICATED_API + 'unsubscribe/'+email, {
        method: 'PUT', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });
}


//// Below here are old APIs that shouldn't be used anymore

export async function LogIn(username, password){
    return await fetch(UNAUTHENTICATED_API + "Guest/Login", {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: {
            'Authorization': 'Basic ' + btoa(encodeURIComponent(username+':'+password))
            // 'Content-Type': 'application/x-www-form-urlencoded',
            // 'Content-Type': 'application/json'
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    }).then(response => console.log(response));
}
    

export async function ReviveMendix(){ // used to check if Mendix is asleep. If so, we wait untill it is active again.
    const tryAgainIn = 10000;
    console.log("revive Mendix");
    const r = fetch(UNAUTHENTICATED_API + 'Common/Revive', {
        mode: 'cors'
    });
    r.then((response) => {
        if (response.status === 200){
            console.log("Mendix is alive");
        }
        else if (response.status === 503) {
            console.log("503; try again: "+tryAgainIn);
            setTimeout(async function () {
                await ReviveMendix()
            }, tryAgainIn);
        }else{
            console.log("Response: "+response.status +"; try again in: "+tryAgainIn);
            setTimeout(async function () {
                await ReviveMendix()
            }, tryAgainIn);
        };
    })
    r.catch(() => {
        console.log("Catch; try again: "+tryAgainIn);
        setTimeout(async function () {
            console.log("try again"+tryAgainIn);
            await ReviveMendix()
        }, tryAgainIn);
    })
}

export async function RegistrateWeekender2022(guest, ReCaptchaToken){
    const payload = {
        "firstname": guest.firstName,
        "lastname": guest.lastName,
        "email": guest.email,
        "host": guest.host,
        "ReCaptchaToken": {
            "token": ReCaptchaToken
        }
    }
    await ReviveMendix();
    return await fetch(UNAUTHENTICATED_API + "Events/Registration/OumSok11rG", {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(payload)
    });
}

export async function GetTikkie(eventId, amount){
    await ReviveMendix();
    console.log("Mendix revived");
    return await fetch(UNAUTHENTICATED_API + "Events/"+ eventId +"/Tikkie?Amount="+ amount, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });
}

export async function SendInvite(guest, ReCaptchaToken, eventId){
    const payload = {
        "firstname": guest.firstName,
        "lastname": guest.lastName,
        "email": guest.email,
        "ReCaptchaToken": ReCaptchaToken,
        "EventId": eventId
        };
    await ReviveMendix();
    return await fetch(UNAUTHENTICATED_API + "Events/"+eventId+'/invite-your-friend', {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(payload)
    });
}

export async function checkInvite(ticketNumber, eventId){
    //await ReviveMendix();
    return fetch(UNAUTHENTICATED_API + "Events/"+eventId+'/Invitation/'+ticketNumber, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });
}



