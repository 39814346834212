import React from "react";
import banner from '../media/images/banner.png';
import {Box, Image, Button, Text} from "grommet";
import { Link } from "react-router-dom";


export const InfoOnSite = (props) => {

return (
<Box animation="fadeIn" align="center">
    <Box gap="1em" fill="horizontal" width={{max:"600px"}} >
        <Image id='headerImage' fit='contain' src={banner} />
        <Text textAlign="center">Check hier alles wat je nodig hebt</Text>
        <Link to="/Weekender2023/Info/Plattegrond">
            <Button default label="Plattegrond/Flatground" fill="horizontal"/>
        </Link>
        <Link to="/Weekender2023/Info/Programma">
            <Button default label="Programma/Programom" fill="horizontal"/>
        </Link>
        <Link to="/Weekender2023/Info/Tijdtafel">
        <   Button default label="Tijdtafel/Timetable" fill="horizontal"/>
        </Link>
        <Button default label="Diensten/Shifts" onClick={() =>  window.open("https://BeatsInTheWoods.com/Weekender2023/Diensten", "_blank")}/>
        <Link to="/Weekender2023/Info/Huisregels">
            <Button default label="Huisregels/Homerulez" fill="horizontal"/>
        </Link>
    </Box>
</Box>

);

}
export default InfoOnSite; 