import React, {useEffect } from "react";
import {SD24_id} from "../constants";
import logo from '../media/images/SD24/SD24_Logo.png';
import {Box, Image, Grommet, Paragraph, Text} from "grommet";
import LoadingIndicator from "../common/LoadingIndicator";
import {checkInvite} from "../mendix_api/UnauthAPI";
import {SD24} from "../common/Theme_SD24"
import { useParams } from "react-router-dom";
import './SD24_Webshop.css';

export const SD24_Webshop = (props) => {
    const [loading, setLoading] = React.useState(true);
    const [apiResponse, setApiResponse] = React.useState({known:false, firstName:""});
    const { ticketcode } = useParams();
    const [setupShop, setSetupShop] = React.useState(true);
    
    useEffect(() =>{
        window.scrollTo(0, 0);     
        console.log("checkInvite");   
        const response = checkInvite(ticketcode, SD24_id);
        response.then(r => {
            if (r.status === 200){
                r.json().then(body => {
                    setApiResponse(body)
                    console.log(apiResponse)
                })}
            else {
                setApiResponse({known:"false"})
            }
            setLoading(false);
        });
        response.catch( r => {
            setApiResponse({known:"false"});
            setLoading(false)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps   
    }, []);

    useEffect(() =>{
        if(apiResponse.known && setupShop){
            console.log("run shop scripts");
            const eventixScript = document.createElement('script');
            eventixScript.src = "https://shop.eventix.io/build/integrate.js"
            eventixScript.async = false;
            document.body.appendChild(eventixScript);
            setSetupShop(false);
        }},[apiResponse,setupShop]);


return (
    <Grommet 
    full
    theme={SD24}
    style={{marginTop:"-58px", height:"120%", minHeight:"100vh"}}
    >
        <Box 
        id="top-box-webshop" 
        className="top-box-webshop"
        animation="fadeIn" 
        background="url('../images/SD24/SD24Bg.png')"
        margin={{top:"-65px"}}
        align="center">
            <Box
                className="logo-box">
                <Image
                    className='logo-image'
                    fit='contain'
                    src={logo}
                />
            </Box>
        
        {loading ? (
            <Box pad="small">
                <Text size="26px">Eén moment, we pakken de kaartjes erbij!</Text>
                <LoadingIndicator/>
            </Box>
        ) : 
        <Box>
        { apiResponse.known ? (
            <Box align="center" pad="none" animation="fadeIn">
                <Box 
                id="shop-info"
                direction="row" 
                align="center"
                justify="center"
                flex="shrink"
                wrap>
                   <Box
                   align="center"
                   className="shoppa">
                       <div id="shop-frame" className="eventix-shop"
                           data-url="https://shop.eventix.io/d08b0108-b634-11ee-a9cb-7e126431635e" 
                           style={{maxWidth: "600px", margin:"0 auto"}}></div>
                   </Box>
                   <Box
                       pad="medium"
                       align="center"
                       animation= "slideDown"
                       className="infobox">
                       <Paragraph size="26px" textAlign='left' className="steps">
                           Hey {apiResponse.firstName}! <br/>
                           <br/>
                           Vet nice dat je (weer) wilt komen dit jaar. Volg hieronder ons stappenplan.<br/>
                           <br/>
                           1. Hier de unieke code ( <span>{ticketcode}</span> ) die je van ons hebt gekregen (lucky bastard).<br/>
                           <br/>
                           2. Vul je code in bij "actiecode". Hierna verschijnen de beschikbare kaarten.<br/>
                           <br/>
                           3. Je kan maximaal 1 kaart selecteren (als ze er nog zijn)<br/>
                           
                       </Paragraph>
                   </Box>
               </Box>
           </Box>
        ): 
            <Box 
            pad="medium"
            align="center"
            animation= "slideDown"
            className="infobox">
                <Paragraph size="26px" textAlign='left'>
                    Je code is helaas verlopen of ongeldig. Probeer een ander of stuur een mail naar:<br/>
                    info@BeatsInTheWoods.com.
                </Paragraph>
            </Box>

        }
        </Box>
        }
    </Box>
    </Grommet>
);
}
export default SD24_Webshop;
