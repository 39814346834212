import React, {useEffect} from "react";
import logo from '../media/images/banner.png';
import {Box, Image, Text} from "grommet";
import {guestIsGoing, ReviveMendixV2} from "../mendix_api/UnauthAPI";
import { useParams } from 'react-router-dom';
import LoadingIndicator from "../common/LoadingIndicator";


export const Weekender24 = (props) => {
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [mendixAwake, setMendixAwake] = React.useState(false);
    const [approve, setApprove] = React.useState();
    const { userId } = useParams();
    const { approveDecline } = useParams();
    const eventId = "0CjtRDJuT8";


    useEffect(() => { 
        const keepWakingMendix = () => {
            setTimeout(() => {
                ReviveMendixV2()
                    .then((r) => {
                        console.log("Mendix is wakker")
    
                    })
                    .catch((e) => {
                        console.log("Mendix is nog steeds in slaap")
                        keepWakingMendix();
                    })
    
              }, 10000);
        };
        ReviveMendixV2()
            .then((r) => {
                if (r.status === 200) {
                    console.log("Mendix is in 1x wakker");
                    setMendixAwake(true);
                } else {
                    keepWakingMendix();
                }
            })
            .catch((e) => {
                keepWakingMendix();
            })
        },[mendixAwake]);

    useEffect(() =>{    
        const handleGuestIsGoing = () => {
            guestIsGoing(userId, eventId, approve).then((response)=>{
                setLoading(false);
                if (response.status >= 200 && response.status < 300){
                    console.log('Succesfull API call:' + response.status)
                    setError(false);
                }
                else {
                    console.log('Failed API call:' + response.status)
                    setError(true);
                }
            }).catch(()=>{
                console.log('Error in API call')
                setLoading(false);
                setError(true);
            });
        };

        if (mendixAwake){   
            if (approve === undefined){
                if(approveDecline === 'approve'){
                    console.log("Guest has approved");
                    setApprove(true);
                } else if (approveDecline === 'decline'){
                    console.log("Guest has declined")
                    setApprove(false);
                } else{
                    console.log("Approval null");
                }
            }
            if (approve !== undefined){
                handleGuestIsGoing();
            }
        }
    }, [approveDecline, approve, mendixAwake, userId]);

        

return (
    <Box animation="fadeIn" align="center">
        <Box gap="1em" fill="horizontal" width={{max:"600px"}} >
            <Image
                id='headerImage'
                fit='contain'
                src={logo}
            />
            <Box pad="large"
                align="center"
                animation= "slideDown"
                >
                {loading ? (
                <Box>
                    <LoadingIndicator/>
                    <Text color="#8574AC" textAlign="center" style={{maxWidth: '580px', margin: "1em"}}>
                    Antwoord registreren
                    </Text>
                </Box>
                ) : null
                }
                {error ? (
                <Text color="red" textAlign="center" style={{maxWidth: '580px', margin: "1em"}}>
                    Kon opgevraagde pagina niet openen.
                    Stuur een mail naar info@BeatsInTheWoods.com en we helpen je graag verder. 
                </Text>
                ) : null             
                }
                {!error && !loading && approve? (
                    <Text color="8574AC" textAlign="center" style={{maxWidth: '580px', margin: "1em"}}>
                    Wat leuk dat je komt! En bedankt voor het doorgeven :) Hopelijk tot snel!!!
                    </Text>
                ):null}
                {!error && !loading && !approve? (
                    <Text color="8574AC" textAlign="center" style={{maxWidth: '580px', margin: "1em"}}>
                    Jammer dat je niet komt. We zullen je niet meer lastig vallen hierover maar toch bedankt voor het doorgeven.
                    </Text>
                ):null}
            </Box>
        </Box>
    </Box>
);

}
export default Weekender24;