import React, {useEffect, useRef} from "react";
import logo from '../media/images/back-in-the-woods.png';
import {Box, Button, Form, FormField, Image, Text, TextInput, ThemeContext} from "grommet";
import LoadingIndicator from "../common/LoadingIndicator";
import {SendInvite} from "../mendix_api/UnauthAPI";
import {RECAPTCHA_KEY} from "../constants";
import ReCAPTCHA from "react-google-recaptcha"

const adjustedLabelMargins = {
    error: {
        margin: 'none',
    },
    help: {
        margin: 'none',
    },
    info: {
        margin: 'none',
        },
    label: {
        margin: 'none',
          },
};

export const InviteYourFriend = (props) => {
    const [loading, setLoading] = React.useState(false);
    const recaptchaRef = React.createRef();
    const [captchaKey, setCaptchaKey] = React.useState(1)
    const eventId = "xWbv0FJMmK";
    const [guest, setGuest] = React.useState({firstname: '', lastname:'', email: ''});
    const [message, setMessage] = React.useState({text: '', color: ''});
    const prevDarkMode = usePrevious(props.darkMode);
    
    /*useEffect(() =>{
        ReviveMendix();
    }, []);*/

    useEffect( () => {
        if (prevDarkMode !== props.darkMode){
            setCaptchaKey(captchaKey+1);
        }
    },[captchaKey, props.darkMode, prevDarkMode]);
    
    useEffect( () => {
    },[loading, message]);

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    };

    function submitForm(){
        const recaptchaValue = recaptchaRef.current.getValue();
        if (recaptchaValue){
            setLoading(true);
            SendInvite(guest, recaptchaValue, eventId).then(r => {
                if (r.status === 200){
                    setMessage({text: 'Hoppa, die uitnodiging is de deur uit!' , color: 'green'})
                }
                else {
                    r.json().then(body =>
                        setMessage({text: body.description, color: 'red'})
                    );
                    setCaptchaKey(captchaKey+1);
            }});
            setLoading(false);
        }
        else{setMessage({text: 'Ben jij een robot?', color: 'red'});}
    }

return (
    <Box animation="fadeIn">
        <Box height='450px' >
            <Image
                id='headerImage'
                fit='contain'
                src={logo}
            />

            <Box pad="large"
                align="center"
                animation= "slideDown"
                >
                <Box width="375px">
                    <Text textAlign='center'>Wil jij graag dat je beste vriend(in) ook naar Beats in the Woods komt?
                        Vul dan onderstaand formulier in en stuur hem/haar/hen direct een uitnodiging!
                    </Text>
                </Box>
                
            </Box>
        </Box>
        {loading ? (
        <LoadingIndicator/>
        ) : (
        <Box align="center" pad="small" animation="fadeIn">
            {message.color !== 'green'? (
            <Form  style={{width:'250px'}}
                messages={{ required: "Volgens mij ben je wat vergeten!" }}
                value={guest}
                onChange={nextValue => setGuest(nextValue)}
                onSubmit={ () => {submitForm()}}>
                <ThemeContext.Extend
                    value={{
                        formField: adjustedLabelMargins,
                    }}
                >
                    <FormField
                        name="firstName"
                        htmlFor="firstName"
                        label="Voornaam:"
                        required
                    >
                        <TextInput id="firstName" name="firstName"/>
                    </FormField>
                    <FormField
                                name="lastName"
                                htmlFor="lastName"
                                label="Achternaam:"
                                required
                    >
                        <TextInput id="lastName" name="lastName"/>
                    </FormField>
                    <FormField
                        name="email"
                        htmlFor="email"
                        label="E-mail:"
                        required
                    >
                        <TextInput id="email" name="email" type="email" />
                    </FormField>
                </ThemeContext.Extend>
                <Box align="center" pad="small">
                    <ReCAPTCHA
                        key={captchaKey}
                        ref={recaptchaRef}
                        sitekey={RECAPTCHA_KEY}
                        onChange={(value) => { if (value){setMessage({text: '', color: ''});}}}
                        theme={props.darkMode ? 'dark':'light' }
                        // size={'compact'}
                    />
                </Box>
                <Box align="center" >
                    <Button type="submit" label="Stuur uitnodiging" primary hoverIndicator="darkgreen"/>
                </Box>
            </Form>):(null)}
            { message.text ? (
                    <Box align='center' style={{paddingTop:'5px'}}>
                        <Text textAlign="center" color={message.color}>
                            {message.text}
                        </Text>
                    </Box>
                ): null }
                    { /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? (
                        <div style={{ minHeight: '150px'}}>

                        </div>
                    ):null}
        </Box>
        )}
    </Box>
);

}
export default InviteYourFriend;