import React, {useEffect} from "react";
import logo from '../media/images/back-in-the-woods.png';
import {Box, Image, Text} from "grommet";
import LoadingIndicator from "../common/LoadingIndicator";
import {GetTikkie} from "../mendix_api/UnauthAPI";


export const Tikkie = (props) => {
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);
    const eventId = "xWbv0FJMmK";
    const [tikkieUrl, setTikkieUrl] = React.useState("");
    
    useEffect(() =>{
         GetTikkie(eventId, props.amount).then(r => {
            console.log(r.status);
            if (r.status === 200){
                console.log("Import body");
                console.log(r);
                r.json().then(body => {
                    console.log(body.tikkieUrl);
                    setTikkieUrl(body.tikkieUrl);
                }).catch(r => {
                    console.log("Couldn't parse body");
                });
                console.log(this.tikkieUrl);
                setLoading(false);
                console.log(this.loading);
            }
            else if (r.status === 406){
                setLoading(false);
                setError(true);
                console.log("Error 406");       
        }}).catch(r => {
            console.log("No Tikkie")
        });
    }, [props.amount]);

    useEffect( () => {

    },[loading]);

    useEffect(() => {
        // tikkieUrl changed
        if (tikkieUrl !== "") {
            console.log("Open Window");
            window.open(tikkieUrl, "_self");
        }
      }, [tikkieUrl])


return (
    <Box animation="fadeIn">
        <Box height='600px' >
            <Image
                id='headerImage'
                fit='contain'
                src={logo}
            />
            <Box pad="large"
                align="center"
                animation= "slideDown"
                >
                {loading ? (
                    <Box>
                        <LoadingIndicator/>
                        <Text color="pink" textAlign="center" style={{maxWidth: '580px', margin: "1em"}}>
                            Even geduld terwijl wij het juiste betaalverzoek er voor jou bijpakken. <br/>
                            Dit kan ongeveer een minuut duren. Je wordt vanzelf doorgestuurd.
                        </Text>
                    </Box>
                ):(<Box/>)}
                {error ? (
                    <Text color="red" textAlign="center" style={{maxWidth: '580px', margin: "1em"}}>
                    Er is helaas geen betaalverzoek meer beschikbaar. Misschien zijn we uitverkocht of misschien zijn gewoon al onze tikkies verlopen<br/>
                    Stuur een mail naar info@BeatsInTheWoods.com en we helpen je graag verder. 
                    </Text>
                ):(<Box/>)}
            </Box>
        </Box>
    </Box>
);

}
export default Tikkie;