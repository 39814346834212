import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import logo from '../media/images/SD24/SD24_Logo.png';
import {Box, Button, Form, FormField, Image, Text, TextInput, Grommet} from "grommet";
import {ReviveMendix} from "../mendix_api/UnauthAPI";
import {SD24} from "../common/Theme_SD24"
import './TicketPortal.css';


export const TicketPortal = (props) => {
    const [accesCode, setInvitationcode] = React.useState({invitationCode:""});
    
    useEffect(() =>{
        ReviveMendix();
    }, []);

    let navigate = useNavigate(); 
    const routeChange = (accesCode) =>{ 
        console.log("open page")
        let path = accesCode.invitationCode; 
        console.log(path)
        navigate(path);
  }

return (
    <Grommet 
    className="grommet-box"
    theme={SD24}
    style={{minHeight: "100.7vh"}}>
        <Box 
        className="background-box"
        animation="fadeIn" 
        align="center"
        background="url('../images/SD24/SD24Bg.png')"
        style={{minHeight: "100.7vh"}}>
            <Box
            id="logoBox"
            width="700px;">
                <Image
                    id='headerImage'
                    fit='cover'
                    src={logo}
                    style={{marginTop:"50px"}}
                />
            </Box>
            <Box width="600px"
                pad="large"
                align="center"
                animation= "slideDown">
                <Text size="custom" textAlign='center'>Welkom bij Sjateau Disceau.
                Gooi snel je unieke toegangscode in onderstaand veld en koop je kaartje.
                </Text>
            </Box>
        <Box align="center" pad="small" animation="fadeIn">
            <Form  style={{width:'250px'}}
                messages={{ required: "Maatje, doe mij die code!" }}
                value={accesCode}
                onChange={nextValue => setInvitationcode(nextValue)}
                onSubmit={ () => {routeChange(accesCode)}}>
                    <FormField
                        name="invitationCode"
                        htmlFor="invitationCode"
                        label="Toegangscode:"
                        required
                    >
                        <TextInput id="invitationCode" name="invitationCode" style={{color:"#EABC48"}}/>
                    </FormField>
                <Box align="center" pad="small">
                </Box>
                <Box align="center" >
                    <Button type="submit" label="Koop kaartje" size="large"/>
                </Box>
            </Form>
        </Box>
    </Box>
    </Grommet>
);
}
export default TicketPortal;