
export const BitW = {
    global: {
        colors: {
            green: '#9ECC84',
            blue: '#42506e',
            pink: '#fb00be',
            darkgreen: '#839b76',
            "grey-1": "#ECE9E3",
            "grey-2": "#CECCC6",
            "grey-3": "#737069",
            "grey-4": "#52504C",
            /* End color definition */
            background: {
                dark: "blue",
                light: "green"
            },
            "background-back": {
                dark: "blue",
                light: "green"
            },
            "background-alt": {
                dark: "green",
                light: "blue"
            },
            "background-front": {
                dark: "grey-3",
                light: "grey-2"
            },
            brandInvert: {
                light: "blue",
                dark: "green"
            },
            brand: {
                light: "green",
                dark: "blue"
            },
            control: {
                dark: "green",
                light: "blue"
            },
            input: {
                background: "darkgreen"
            },
            text: {
                dark: "green",
                light: "blue"
            }
        },
        focus: {
            border: {
            color: "green"
            }
        },
        font: {
            family: 'brandon_grotesquebold',
            size: '18px',
            height: '20px',
        },
        textInput: {
            extend: {
                color: 'darkgreen'
            },
        },

    },
}